import axios from "axios";
import { toast } from 'react-toastify';
const apiURL2 = process.env.REACT_APP_API_URL2;

const login = async (email, password, handleShowFooterModal, allowLogout, handleShowFooterModalConfirmation) => {
    let deviceId = localStorage.getItem("deviceId") ? localStorage.getItem("deviceId") : ""

    return axios.post(apiURL2 + "/public/login", {
        email,
        password,
        deviceId: deviceId,
        allowLogout: allowLogout
    }).then((response) => {
        if (response.data.success === true) {
            const token = response.data.data.token;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            localStorage.setItem("deviceId", response.data.data.deviceId);
            handleShowFooterModal()
            toast.success("Επιτυχής Σύνδεση.");
            return response.data;
        } else if (response.data.success === false) {
            if (response.data.data === 10010) {
                handleShowFooterModalConfirmation()
            } else {
                toast.error(response.data.message);
            }
        }
    }).catch((error) => {
        if (error) toast.error("Service is unavailable for the moment. Please try again later.")
    })
}


const forgotPasssword = async (email, navigate) => {
    return axios
        .post(apiURL2 + "/public/forgot-password", {
            email
        })
        .then((response) => {
            if (response.data.success === true) {
                toast.success('Password link has been sent successfully')
                navigate('/email-sent');
                return response.data
            } else {
                toast.error('Παρακαλώ Ξαναπροσπαθήστε!');
                return false
            }
        })
        .catch((error) => {
            if (error) {
                toast.error("Service is unavailable for the moment. Please try again later.");
            }
        });
};



const resetPassword = async (pass, cPassword, code, navigate) => {

    return axios
        .post(apiURL2 + "/public/reset-password", {
            password_confirmation:cPassword,
            password:pass,
            code:code
        })
        .then((response) => {
            if (response.data.success === true) {
                toast.success("Password is reset successfully.");
                const token = response.data.data.token;
                localStorage.setItem("token", token);
                localStorage.setItem("user", JSON.stringify(response.data.data));
                navigate('/login');
            }
            if (response.data.success === false) {
                toast.error(response.data.message)
                console.log(response.data)
                localStorage.clear();
                navigate('/login');
            }
        })
        .catch((error) => {
            if (error) {
                toast.error("Server is down for maintenance, please try again later");
            }
        });
};
const logout = () => {
    localStorage.removeItem("user");
};

export default {
    login,
    forgotPasssword,
    resetPassword,
    logout,
};
