import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {toast} from "react-toastify";
import {Button, Col, Modal, ModalBody, Row} from "react-bootstrap";
import Input from "../common/Input";
import zxcvbn from 'zxcvbn';
import moment from 'moment';
import {
    deleteObjectFromArrayOfObjects,
    getDefaultOptionByValue,
    getDOYOptions,
    getInstallationsTypes,
    getMainInstallation,
    isValidEmail
} from "../../helpers/commonFunctions";
import Dropdown from "../primer/Dropdown";
import EditableTable from "../primer/Editable-Table";
import {logout} from "../../actions/auth";

const Register = () => {
    const clientInstallationsColumns = [
        {
            name: "Αριθμός Εγκατάστασης Μητρώου",
            field: "installationMasterId",
            width: "5%",
            editable:true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Τύπος Εγκατάστασης",
            field: "type",
            editable: true,
            width: "15%",
            inputType: {
                options: getInstallationsTypes(),
                type: 'dropdown',
                config: {
                    searchEnable: true
                }
            }
        },
        {
            name: "Διεύθυνση",
            field: "address",
            width: "20%",
            editable:true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Τ.Κ.",
            field: "postalCode",
            width: "7%",
            editable:true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Δ.Ο.Υ.",
            field: "doy",
            editable: true,
            width: "15%",
            inputType: {
                options: getDOYOptions(),
                type: 'dropdown',
                config: {
                    searchEnable: true
                }
            }
        },
        {
            name: "Πόλη",
            field: "city",
            width: "15%",
            editable: true,
            inputType: {
                type: 'text',
            }
        },
        {
            name: "Τηλέφωνο",
            field: "phone",
            width: "20%",
            editable: true,
            inputType: {
                type: 'text',
            }
        }
    ];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiURL2 = process.env.REACT_APP_API_URL2;
    const currentDate = moment();
    const [showModal, setShowModal] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const newInstallationInitialValue = {
        type: "",
        id: 1,
        address: "",
        doy: "",
        postalCode: "",
        phone: "",
        city: "",
        installationMasterId: "",
    };
    const newUserInitialValue = {
        firstName: "",
        lastName: "",
        userName: "",
        userType: "User",
        lang: "el",
        status: "Active",
        password: "",
        email: "",
        showPassword: false
    }
    const initialCompanyData = {
        companyParternshipStartOn: currentDate.toString(),
        companyEstablishedDate: currentDate.toString(),
        companyExpireDate: currentDate.toString(),
        headquartersAddress: "",
        name: "",
        smallName: "",
        vatNumber: "",
        doy: "",
        activity: "",
        country: "GR",
        city: "",
        postalCode: "",
        email: "",
        gemhNo: "",
        phone: "",
        isActive: "Active",
        myDataApiEnvironment: "production",
        myDataMode: "live",
        myDataApiUserId: "",
        representativeName: "",
        representativeSurname: "",
        representativeVatNumber: "",
        myDataApiSubscriptionKey: "",
        primerMyDataApiEnvironment: "production",
        primerMyDataApiUsername: "",
        primerMyDataApiPassword: "",
        primerMyDataApiRemainingDocuments: "",
        primerMyDataApiMonthlyRemainingDocuments: "",
        primerMyDataApiStartDate: "",
        primerMyDataApiEndDate: "",
        primerMyDataApiSerialNumber: "",
        installations: [{
            type: "Κεντρικό",
            id: 1,
            address: "",
            doy: "",
            phone: "",
            city: "",
            installationMasterId: "0",
            }],
        users: [],
        years: [],
        productSku: "",
        myDataReport: "false",
        duration: 0
    };
    const [companyData, setCompanyData] = useState(initialCompanyData);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const licenseKey = searchParams.get('licenseKey');
    const vatNumber = searchParams.get('vatNumber');
    // from url directly submit
    useEffect( () => {
        if (licenseKey) {
            validateFromUrl(licenseKey);
        }
    }, [licenseKey]);
    const validateFromUrl = async (licenseKey) => {
        await axios
            .post(apiURL2 + "/public/company/check-license-key", {
                licenseKey: licenseKey
            })
            .then((response) => {
                if (response.data?.status === "200") {
                    toast.success("Ο Σειριακός Αριθμός επιβεβαιώθηκε επιτυχώς.");
                    let erpUsers = [];
                    if (response.data.data?.erpUsers) {
                        for (let i = 0; i < response.data.data?.erpUsers; i++) {
                            erpUsers.push(newUserInitialValue);
                        }
                    }
                    setCompanyData({
                        ...companyData,
                        primerMyDataApiSerialNumber: response.data.data.licenseKey,
                        companyExpireDate: response.data?.data?.duration ? currentDate.add(response.data.data.duration, 'days').toString() : currentDate,
                        primerMyDataApiUsername: response.data.data.username,
                        primerMyDataApiPassword: response.data.data.password,
                        productSku: response.data.data.productSku,
                        myDataReport: response.data.data.myDataReport,
                        users: erpUsers
                    });
                    setShowModal(true);
                    if (vatNumber) {
                          getTaxiNetDetails(vatNumber, {
                              ...companyData,
                              primerMyDataApiSerialNumber: response.data.data.licenseKey,
                              companyExpireDate: response.data?.data?.duration ? currentDate.add(response.data.data.duration, 'days').toString() : currentDate,
                              primerMyDataApiUsername: response.data.data.username,
                              primerMyDataApiPassword: response.data.data.password,
                              productSku: response.data.data.productSku,
                              myDataReport: response.data.data.myDataReport,
                              users: erpUsers
                          });
                    }
                } else {
                    toast.error(response.data.message)
                    setCompanyData(initialCompanyData);
                }
            })
            .catch((error) => {
                if (error) {
                    toast.error("Server is down for maintenance, please try again later");
                }
                setCompanyData(initialCompanyData);
            });
    }
    const handleModalClose = () => {
        setCompanyData(initialCompanyData);
        setShowModal(false);
        setShowErrors(false);
    }
    const handleModalSuccessClose = () => {
        setShowModalSuccess(false);
        toast.success("Επιτυχής Ενεργοποίηση! Παρακαλώ συνδεθείτε με τον νέο σας χρήστη.");
        localStorage.clear();
        dispatch(logout());
        navigate(`/login`);
    }
    const getTaxiNetDetails = async (data, updatedCompanyData) => {
        if (data === "" || data === null || data === undefined) {
            toast.error("Please input a vat Number to check.");
            return;
        }
        const d = {
            vatNumber: data,
            onlyAADE: "true",
        };
        const abort = axios.CancelToken.source();
        const timer = setTimeout(() => abort.cancel(`timeout`), 10000);
        axios
            .post(apiURL2 + `/public/company/vat-detail`, d)
            .then((res) => {
                if (res.data && res.data.success === true) {
                    clearTimeout(timer)
                    let companyDetails = res.data.data;
                    let newCompany;
                    if(updatedCompanyData){
                        newCompany = Object.assign({}, updatedCompanyData);
                    } else {
                        newCompany = Object.assign({}, companyData);
                    }
                    newCompany.name = companyDetails?.name !== undefined ? companyDetails?.name : "";
                    newCompany.vatNumber = data;
                    newCompany.smallName = companyDetails?.smallName !== undefined ? companyDetails?.smallName : "";
                    newCompany.activity = companyDetails?.activity !== undefined ? companyDetails?.activity : "";
                    newCompany.postalCode = companyDetails?.tk !== undefined ? companyDetails?.tk : "";
                    newCompany.doy = getDefaultOptionByValue(getDOYOptions(), Number(companyDetails?.doyCode), "value", "label", "value").label;
                    newCompany.city = companyDetails?.city !== undefined ? companyDetails?.city : "";
                    newCompany.headquartersAddress = companyDetails?.address !== undefined ? companyDetails?.address : companyDetails?.headquartersAddress;
                    let defaultInstallation = [
                        {
                            installationMasterId: "0",
                            type: getMainInstallation()?.label,
                            address: newCompany.headquartersAddress,
                            doy: newCompany.doy,
                            city: newCompany.city,
                            postalCode: newCompany.postalCode,
                            phone: newCompany.phone,
                            isActive: "active"
                        },
                    ];
                    setCompanyData({...newCompany, installations: defaultInstallation});
                    toast.success("Τα δεδομένα για αυτό το Α.Φ.Μ. αντλήθηκαν με επιτυχία από το Μητρώο");
                } else {
                    toast.error(res.data.message);
                }
                if (res.data && res.data.status === "422") {
                    console.log("Permissions not found.");
                }
            })
            .catch((error) => {
                console.log(error);
                if(error.response?.status === 400){
                    toast.error(error.response?.data?.message);
                }
            });
    };

    const handleOnChange = (e, type = "default", ddname = '') => {
        const updatedCompanyData = { ...companyData };
        let name;
        let value;

        if (type === 'dd' || type === 'ac') {
            value = e.value;
            name = ddname;
        } else {
            value = e.target.value;
            name = e.target.name;
        }

        if (ddname === "doy") {
            value = e.label;
        }
        updatedCompanyData[name] = value;
        const installations = updatedCompanyData?.installations;
        if (installations && installations.length > 0) {
            const index = installations.findIndex(obj => obj.installationMasterId === "0");
            if (index !== -1) {
                const updatedInstallation = installations[index];
                updatedInstallation.address = updatedCompanyData.headquartersAddress;
                updatedInstallation.doy = updatedCompanyData.doy;
                updatedInstallation.city = updatedCompanyData.city;
                updatedInstallation.phone = updatedCompanyData.phone;
                updatedInstallation.postalCode = updatedCompanyData.postalCode;
            }
        }

        setCompanyData(updatedCompanyData);
    };

    const handleOnChangeUsers = (e, index, showPassword) => {
        const { name, value } = e.target;
        const updatedUsers = [...companyData.users];
        if (index >= 0 && index < updatedUsers.length) {
            if(showPassword){
                updatedUsers[index] = { ...updatedUsers[index], showPassword: showPassword === "true"};
            } else {
                updatedUsers[index] = { ...updatedUsers[index], [name]: value };
            }
        }
        setCompanyData((prevData) => ({...prevData, users: updatedUsers}));
    }

    const handleRegister = () => {
        setShowErrors(true);
        let isValid = true;
        let requestData = {...companyData};
        if(requestData.name === "" || requestData.headquartersAddress === "" || requestData.email === "" || requestData.mobile === "" || requestData.vatNumber === "" || requestData.doy === "" || !isValidEmail(requestData.email) ||
            requestData.activity === "" || requestData.city === "" || requestData.postalCode === "" || requestData.phone === "" || requestData.representativeSurname === "" || requestData.representativeName === "" || requestData.representativeVatNumber === ""){
            isValid = false;
            toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία και ξαναπροσπαθήστε!");
        }
        if(requestData.installations && requestData.installations .length > 0){
            requestData.installations.map((installation,index) => {
                if(index !== 0 && (!installation?.installationMasterId || !installation?.address || !installation?.city || !installation?.postalCode || !installation?.type || !installation?.doy || !installation?.phone)){
                    isValid = false;
                    toast.error("Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία στις εγκαταστάσεις και ξαναπροσπαθήστε!");
                }
            })
        }
        if(requestData.users && requestData.users .length > 0){
            requestData.users.map((user, userIndex) => {
                if(!user?.firstName || !user?.lastName || !user?.userName || !user?.email || !isValidEmail(user.email) || !user?.password){
                    isValid = false;
                    toast.error(`Παρακαλώ ελέγξτε όλα τα απαραίτητα πεδία στον Χρήστη ${userIndex + 1} και ξαναπροσπαθήστε!`);
                }
                if(user?.password){
                    const passwordStrength = zxcvbn(user.password);
                    console.log(passwordStrength)
                    if (passwordStrength.score < 3) {
                        isValid = false;
                        // Password is not strong enough
                        toast.error(`Υπήρξε πρόβλημα κατά την δημιουργία κωδικού στον Χρήστη ${userIndex + 1}. ` + passwordStrength.feedback.warning + '. ' + passwordStrength.feedback.suggestions);
                    }
                }
            })
        }
        if(isValid) {
            axios
                .post(apiURL2 + "/public/company/register", requestData)
                .then((response) => {
                    if (response.data?.status === "200") {
                        setShowModal(false);
                        setShowModalSuccess(true);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error) {
                        toast.error("Server is down for maintenance, please try again later");
                    }
                });
        }
    }
    const parentMydataActions = {
        insert: (e, data) => {
            let tempInstallations = Object.assign({},  newInstallationInitialValue);
                tempInstallations["isActive"] = "active";
                tempInstallations[data.field] = data.value;
                if(data.field === "type" && data.value === "1"){
                    toast.error("Δεν μπορείτε να προσθέσετε 2 κεντρικές εγκαταστάσεις σε μια εταιρία")
                } else {
                    if (data.field === "type") {
                        tempInstallations[data.field] = getDefaultOptionByValue(getInstallationsTypes(), data.value, "value", "label", "value").label;
                    }
                    if (data.field === "doy") {
                        tempInstallations[data.field] = getDefaultOptionByValue(getDOYOptions(), data.value, "value", "label", "value").label;
                    }
                    if (tempInstallations && (tempInstallations?.installationMasterId !== "" || tempInstallations?.address !== "" || tempInstallations?.doy !== "" || tempInstallations?.phone !== "" || tempInstallations?.city !== "" || tempInstallations?.type !== "")) {
                        let oldInstallations = companyData.installations;
                        let updatedInstallation = Object.assign({}, tempInstallations);
                            updatedInstallation.id = oldInstallations.length + 1;
                        console.log(updatedInstallation.id)
                        if (updatedInstallation && (updatedInstallation.address !== '' || updatedInstallation.doy !== '' || updatedInstallation.city !== '' || updatedInstallation.country !== '')) {
                            setCompanyData({...companyData, installations: [...oldInstallations, updatedInstallation]});
                        }
                    }
                }
        },
        update: (data, id) => {
            let installations = companyData.installations.slice();
            if (installations.find(item => item.id === id) !== undefined) {
                if(data?.type && data.type === "1"){
                    toast.error("Δεν μπορείτε να προσθέσετε 2 κεντρικές εγκαταστάσεις σε μια εταιρία")
                } else {
                    if (data.type) {
                        data.type = getDefaultOptionByValue(getInstallationsTypes(), data.type, "value", "label", "value").label;
                    }
                    if (data.doy) {
                        data.doy = getDefaultOptionByValue(getDOYOptions(), data.doy, "value", "label", "value").label;
                    }
                    installations[id - 1] = {...installations[id - 1], ...data};
                    setCompanyData({...companyData, installations: installations}); // update data
                }
            }
        },
        selected: () => {
            console.log('selected mydata row clicked');
        },
        delete: (id) => {
            let installations = companyData.installations.slice();
            if (id !== '' && Number.isInteger(id)) {
                installations = deleteObjectFromArrayOfObjects(installations, id, 'id');
                setCompanyData({...companyData, installations: installations}); // update data
            }
        }
    }
    return (
        <React.Fragment>
            <Formik
                initialValues={{ licenseKey: ""}}
                onSubmit={async (values) => {
                    setCompanyData(initialCompanyData);
                    await axios
                        .post(apiURL2 + "/public/company/check-license-key", {
                            licenseKey: values.licenseKey
                        })
                        .then((response) => {
                            if (response.data?.status === "200") {
                                toast.success("Ο Σειριακός Αριθμός επιβεβαιώθηκε επιτυχώς.");
                                let erpUsers = [];
                                if (response.data.data?.erpUsers) {
                                    for (let i = 0; i < response.data.data?.erpUsers; i++) {
                                        erpUsers.push(newUserInitialValue);
                                    }
                                }
                                setCompanyData({
                                    ...companyData,
                                    primerMyDataApiSerialNumber: response.data.data.licenseKey,
                                    companyExpireDate: response.data?.data?.duration ? currentDate.add(response.data.data.duration, 'days').toString() : currentDate,
                                    primerMyDataApiUsername: response.data.data.username,
                                    primerMyDataApiPassword: response.data.data.password,
                                    productSku: response.data.data.productSku,
                                    myDataReport: response.data.data.myDataReport,
                                    users: erpUsers
                                });
                                setShowModal(true);
                            } else {
                                toast.error(response.data.message)
                                setCompanyData(initialCompanyData);
                            }
                        })
                        .catch((error) => {
                            if (error) {
                                toast.error("Server is down for maintenance, please try again later");
                            }
                            setCompanyData(initialCompanyData);
                        });
                }}
                const
                validationSchema={Yup.object().shape({
                    licenseKey: Yup.string().required("Απαιτείται Σειριακός Αριθμός"),
                })}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;
                    return (
                        <div style={{marginTop: "19vh"}}>
                            <div className="row justify-content-center align-items-center mt-5">
                                <div className="col-md-6">
                                    <div className="authincation-content">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="login-banner-txt">
                                                    <h4 className="text-center">Εγγραφή</h4>
                                                </div>
                                                <div className="auth-form">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 mx-auto">
                                                            <a href="/">
                                                                <img src="assets/images/prrimer-logo.png" className="img-fluid login-logo-center" alt="logo"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                        <h4 className="text-center">Συμπληρώστε τον σειριακό αριθμό που λάβατε από την αγορά του πακέτου σας</h4>
                                                    <form onSubmit={handleSubmit} autoComplete="off">
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-user"></i>
                              </span>
                                            </div>
                                            <input
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.licenseKey}
                                                id="licenseKey"
                                                type="text"
                                                name="licenseKey"
                                                className={`form-control ${errors.licenseKey && touched.licenseKey && "error"}`}
                                                placeholder="Συμπληρώστε τον σειριακό αριθμό"
                                            />
                                        </div>
                                        {errors.licenseKey && touched.licenseKey && (<div className="input-feedback">{errors.licenseKey}</div>)}
                                        <div className="form-row justify-content-center mt-5">
                                            <div>
                                                <button type="submit" className="btn btn-primary btn-block">Εγγραφή</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                    );
                }}
            </Formik>
            <Modal className="themeModalBig" show={showModal} onHide={handleModalClose}>
                <Modal.Header>
                    <Modal.Title>Εγγραφή Νέας Εταιρίας</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="text-muted mb-2" style={{textAlign: "left"}}>
                        <i>Στοιχεία Εταιρίας</i>
                    </div>
                    <Row>
                        <Col sm="12" md="5">
                            <div className="mb-3">
                                <Input
                                    label="Επωνυμία/Ονοματεπώνυμο"
                                    required={true}
                                    className="w-100"
                                    name="name"
                                    charLimit={1000}
                                    type="text"
                                    placeholder="Εισάγετε Επωνυμία"
                                    value={companyData?.name}
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {showErrors && companyData.name === "" && (<div className="text-danger">Η Επωνυμία είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                        </Col>
                        <Col sm="12" md="4">
                            <Input
                                label="Διακριτικός Τίτλος"
                                className="mb-3 w-100"
                                name="smallName"
                                type="text"
                                placeholder="Εισάγετε Διακριτικό Τίτλο"
                                value={companyData?.smallName}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                    </Row>
                     <Row>
                        <Col sm="12" md="4">
                            <div className="mb-3">
                                <Input
                                    label="Α.Φ.Μ."
                                    className="w-100 bg-white"
                                    name="vatNumber"
                                    required={true}
                                    type="number"
                                    value={companyData.vatNumber}
                                    placeholder="Εισάγετε Α.Φ.Μ."
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {showErrors && companyData.vatNumber === "" && (<div className="text-danger">Το Α.Φ.Μ. είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                        </Col>
                        <Col sm="12" md="2" className="d-flex align-items-center">
                            <Button onClick={() => getTaxiNetDetails(companyData.vatNumber)} size="sm" className="mb-0" type="button">
                                Αυτόματη Συμπλήρωση από το Μητρώο
                            </Button>
                        </Col>
                        <Col sm="12" md="3">
                            <Dropdown
                                label="ΔΟΥ"
                                classes="w-100"
                                required={true}
                                searchEnable={true}
                                name="doy"
                                key={Math.random()}
                                defaultValue={getDefaultOptionByValue(getDOYOptions(), companyData.doy, "label", "label", "value")}
                                options={getDOYOptions()}
                                onChange={(e) => handleOnChange(e, "dd", "doy")}
                            />
                            {showErrors && companyData.doy === "" && (<div className="text-danger">Η ΔΟΥ είναι υποχρεωτικό πεδίο.</div>)}
                        </Col>
                        <Col sm="12" md="3">
                            <Input
                                label="Δραστηριότητα"
                                className="w-100"
                                name="activity"
                                type="text"
                                required={true}
                                charLimit={200}
                                value={companyData.activity}
                                placeholder="Εισάγετε Δραστηριότητα"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {showErrors && companyData.activity === "" && (<div className="text-danger">Η Δραστηριότητα είναι υποχρεωτικό πεδίο.</div>)}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="4">
                            <div className="mb-3">
                                <Input
                                    label="Διεύθυνση"
                                    className="w-100"
                                    name="headquartersAddress"
                                    type="text"
                                    charLimit={300}
                                    required={true}
                                    value={companyData.headquartersAddress}
                                    placeholder="Εισάγετε Διεύθυνση"
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {showErrors && companyData.headquartersAddress === "" && (<div className="text-danger">Η Διεύθυνση είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                        </Col>
                        <Col sm="12" md="3">
                            <div className="mb-3">
                                <Input
                                    label="Πόλη"
                                    className="w-100"
                                    name="city"
                                    required={true}
                                    type="text"
                                    placeholder="Εισάγετε Πόλη"
                                    value={companyData.city}
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {showErrors && companyData.city === "" && (<div className="text-danger">Η Πόλη είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                        </Col>
                        <Col sm="12" md="2">
                            <div className="mb-3">
                                <Input
                                    label="Ταχυδρομικός Κώδικας"
                                    className="w-100"
                                    name="postalCode"
                                    placeholder="Εισάγετε Τ.Κ."
                                    required={true}
                                    value={companyData.postalCode}
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {showErrors && companyData.postalCode === "" && (
                                    <div className="text-danger">Ο Τ.Κ. είναι υποχρεωτικό πεδίο.</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="3">
                            <div className="mb-3">
                                <Input
                                    label="Email"
                                    className="w-100"
                                    name="email"
                                    type="email"
                                    value={companyData.email}
                                    placeholder="eg. example@example.com"
                                    onChange={(e) => handleOnChange(e)}
                                    required={companyData.email === ""}
                                />
                                {showErrors && companyData.email !== "" && !isValidEmail(companyData.email) && (<div className="text-danger">Λάθος email.</div>)}
                                {showErrors && companyData.email === "" && (<div className="text-danger">To email είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                        </Col>
                        <Col sm="12" md="3">
                            <Input
                                label="Αριθμός ΓΕΜΗ"
                                className="mb-3 w-100"
                                name="gemhNo"
                                type="text"
                                value={companyData.gemhNo}
                                placeholder="Εισάγετε Αριθμό ΓΕΜΗ"
                                onChange={(e) => handleOnChange(e)}
                            />
                        </Col>
                        <Col sm="12" md="3">
                            <Input
                                label="Τηλέφωνο"
                                className="mb-3 w-100"
                                name="phone"
                                type="tel"
                                charLimit={20}
                                value={companyData.phone}
                                placeholder="Εισάγετε Τηλέφωνο"
                                onChange={(e) => handleOnChange(e)}
                                required={true}
                            />
                            {showErrors && companyData.phone === "" && (<div className="text-danger">To Τηλέφωνο είναι υποχρεωτικό πεδίο.</div>)}
                        </Col>
                        <Col sm="12" md="3">
                            <Input
                                label="Κινητό Τηλέφωνο"
                                className="mb-3 w-100"
                                name="mobileNo"
                                type="tel"
                                charLimit={20}
                                value={companyData.mobile}
                                placeholder="Εισάγετε Κινητό Τηλέφωνο"
                                onChange={(e) => handleOnChange(e)}
                                required={true}
                            />
                            {showErrors && companyData.mobile === "" && (<div className="text-danger">To Κινητό Τηλέφωνο είναι υποχρεωτικό πεδίο.</div>)}
                        </Col>
                    </Row>
                <hr />
                    <div className="text-muted mb-2" style={{textAlign: "left"}}>
                        <i>Στοιχεία Εκπροσώπου</i>
                    </div>
                    <label className="w-100 text-muted" style={{textAlign: "left"}}>
                        <i>Εδώ εισάγετε τα στοιχεία του Διαχειριστή της εταιρίας τα οποία είναι απαραίτητα για την σύναψη της σύμβασης χρήσης MyData μέσω παρόχου.</i>
                        <br></br>
                        <i>Εάν πρόκειται για ατομική επιχείρηση τότε εισάγετε τα στοιχεία του ιδιοκτήτη.</i>
                    </label>
                    <Row>
                        <Col sm="12" md="4">
                            <div className="mb-3">
                                <Input
                                    label="Όνομα Νομίμου Εκπροσώπου"
                                    required={true}
                                    className="w-100"
                                    name="representativeName"
                                    charLimit={1000}
                                    type="text"
                                    placeholder="Εισάγετε Όνομα Εκπροσώπου"
                                    value={companyData?.representativeName}
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {showErrors && companyData.representativeName === "" && (<div className="text-danger">Το Όνομα Εκπροσώπου είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                        </Col>
                        <Col sm="12" md="4">
                            <div className="mb-3">
                                <Input
                                    label="Επώνυμο Νομίμου Εκπροσώπου"
                                    required={true}
                                    className="w-100"
                                    name="representativeSurname"
                                    charLimit={1000}
                                    type="text"
                                    placeholder="Εισάγετε Επώνυμο Εκπροσώπου"
                                    value={companyData?.representativeSurname}
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {showErrors && companyData.representativeSurname === "" && (<div className="text-danger">Το Επώνυμο Εκπροσώπου είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                        </Col>
                        <Col sm="12" md="4">
                            <div className="mb-3">
                                <Input
                                    label="Α.Φ.Μ. Νομίμου Εκπροσώπου"
                                    required={true}
                                    className="w-100"
                                    name="representativeVatNumber"
                                    charLimit={1000}
                                    type="text"
                                    placeholder="Εισάγετε Α.Φ.Μ. Εκπροσώπου"
                                    value={companyData?.representativeVatNumber}
                                    onChange={(e) => handleOnChange(e)}
                                />
                                {showErrors && companyData.representativeVatNumber === "" && (<div className="text-danger">Το Α.Φ.Μ. Εκπροσώπου είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <p className="text-muted" style={{float: "left"}}><i>Εγκαταστάσεις:</i></p>
                        <EditableTable
                            tableName="Company New Installations"
                            key={Math.random()}
                            allowActions={true}
                            actionButtons={{
                                edit: false,
                                print: false,
                                export: false,
                                delete: true,
                            }}
                            showErrors={showErrors}
                            allowToggleColumns={false}
                            columns={clientInstallationsColumns}
                            allowInsertRow={true}
                            enableNewRowCheckbox={true}
                            data={companyData.installations}
                            onUpdate={parentMydataActions}
                        />
                    <hr />
                    {companyData?.users && companyData?.users.length > 0 && (
                        <div>
                        <p className="text-muted"><i>Χρήστες:</i></p>
                        </div>
                    )}
                    {companyData?.users && companyData?.users.length > 0 && companyData.users.map((user, userIndex) => { return (
                        <React.Fragment key={userIndex}>
                            <hr />
                    <p className="text-muted" style={{float: "left"}}><i>Χρήστης {userIndex + 1}:</i></p>
                            <br></br>
                    <Row>
                        <Col sm="12" md="3">
                            <Input
                                label="Όνομα"
                                className="mb-3 w-100"
                                name="firstName"
                                type="text"
                                value={user.firstName}
                                placeholder="Εισάγετε Ονομα"
                                onChange={(e) => handleOnChangeUsers(e, userIndex)}
                                required={true}
                            />
                            {showErrors && user.firstName === "" && (<div className="text-danger">Το Όνομα είναι υποχρεωτικό πεδίο.</div>)}
                        </Col>
                        <Col sm="12" md="3">
                            <Input
                                label="Επώνυμο"
                                className="mb-3 w-100"
                                name="lastName"
                                type="text"
                                value={user.lastName}
                                placeholder="Εισάγετε Επώνυμο"
                                onChange={(e) => handleOnChangeUsers(e, userIndex)}
                                required={true}
                            />
                            {showErrors && user.lastName === "" && (<div className="text-danger">Το Επώνυμο είναι υποχρεωτικό πεδίο.</div>)}
                        </Col>
                        <Col sm="12" md="3">
                            <Input
                                label="Email"
                                className="mb-3 w-100"
                                name="email"
                                type="text"
                                value={user.email}
                                placeholder="Εισάγετε Email"
                                onChange={(e) => handleOnChangeUsers(e, userIndex)}
                                required={true}
                            />
                            {showErrors && user.email === "" && (<div className="text-danger">Το Email είναι υποχρεωτικό πεδίο.</div>)}
                            {showErrors && user.email !== "" && !isValidEmail(user.email) && (<div className="text-danger">Λάθος email.</div>)}
                        </Col>
                        <Col sm="12" md="3"></Col>
                        <Col sm="12" md="3">
                            <Input
                                label="Όνομα Χρήστη(Username)"
                                className="mb-3 w-100"
                                name="userName"
                                type="text"
                                value={user.userName}
                                placeholder="Εισάγετε Όνομα Χρήστη(Username)"
                                onChange={(e) => handleOnChangeUsers(e, userIndex)}
                                required={true}
                            />
                            {showErrors && user.userName === "" && (<div className="text-danger">Το Όνομα Χρήστη είναι υποχρεωτικό πεδίο.</div>)}
                        </Col>
                        <Col sm="12" md="3">
                            <div className="mb-3 password-field">
                            <Input
                                autoComplete="false"
                                label="Password"
                                name="password"
                                value={user.password}
                                placeholder="Add password"
                                required={true}
                                type={user.showPassword ? "text" : "password"}
                                charLimit="30"
                                onChange={(e) => handleOnChangeUsers(e, userIndex)}
                            />
                            {!user.showPassword && (<i onClick={(e) => handleOnChangeUsers(e, userIndex, "true")} className="fas fa-eye"></i>)}
                            {user.showPassword && (<i onClick={(e) => handleOnChangeUsers(e, userIndex, "false")} className="fas fa-eye-slash"></i>)}
                            {showErrors && user.password === "" && (<div className="text-danger">Ο Κωδικός είναι υποχρεωτικό πεδίο.</div>)}
                            </div>
                            </Col>
                    </Row>
                        </React.Fragment>
                    )})}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleRegister}>
                        Εγγραφή
                    </Button>
                    <Button variant="outline-primary" onClick={handleModalClose}>
                        Ακύρωση
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal className="themeModal" show={showModalSuccess} centered onHide={handleModalSuccessClose}>
                <Modal.Header>
                    <Modal.Title>Επιτυχής Εγγραφη</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    Η Εταιρία και οι χρήστες ενεργοποιήθηκαν επιτυχώς! Πλέον μπορείτε να συνδεθείτε άμεσα στο ERP και να ξεκινήσετε την χρήση του. Θα σας σταλούν εντός ολίγων λεπτών αναλυτικές οδηγίες στο email της εταιρίας που καταχωρήσατε κατά την εγγραφή για την υπογραφή της σύμβασης και αποδοχή χρήσης Παρόχου MyData. Προσοχή! Εάν δεν ολοκληρωθεί αυτό το βήμα η έκδοση παραστατικών μέσα από το ERP είναι αδύνατη.
                </ModalBody>
            <Modal.Footer>
                <Button onClick={handleModalSuccessClose}>ΟΚ</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
    );
};

export default Register;
