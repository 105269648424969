import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiURL2 = process.env.REACT_APP_API_URL2;
  const[codeStatus ,setCodeStatus] = useState("inprogress");
  let params = new URLSearchParams(window.location.search);
  let code = params.get("code");
  useEffect(() => {
    if (code) {
      axios
        .post(apiURL2 + `/public/verify-code`, { code: Number(code) })
        .then((response) => {
          if (response.data.success === false) {
            setCodeStatus ("expire");
          } else {
            setCodeStatus("valid");
            const token = response.data.data.token;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            localStorage.setItem("deviceId", response.data.data.deviceId);

            setTimeout(() => {
              navigate(`/login`);
            }, 3000);
          }
        })
        .catch((error) => {
          if (error) {
            toast.error(
              "Service is unavailable for the moment. Please try again later."
            );
          }
        });
    } else {
      toast.error("something went wrong");
    }
  }, []);

  const resendLink = () => {
    axios
      .post(apiURL2 + `/public/resend-verification-code`, { code: code })
      .then((response) => {
        console.log(response);
        if (response.data.success === false) {
          toast.error(
            "Service is unavailable for the moment. Please try again later."
          );
        } else {
          toast.success("Verfication code is resent");
        }
      })
      .catch((error) => {
        if (error) {
          toast.error(
            "Service is unavailable for the moment. Please try again later."
          );
        }
      });
  };

  return (
    <div className="row align-item-center justify-content-center" style={{marginTop: "19vh"}}>
      <div className="col-md-7">
        <div className="authincation-content">
          <div className="row align-item-center">
            <div className="col-xl-12">
              <div className="login-banner-txt"></div>
              <div className="auth-form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 mx-auto">
                    <a href="/">
                      <img
                        src="assets/images/prrimer-logo.png"
                        className="img-fluid login-logo-center"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                {codeStatus === "valid" && (
                  <div className="row">
                    {" "}
                    <text
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        color: "green",
                      }}
                    >
                      Your email has been verified.
                    </text>
                  </div>
                )}
                {codeStatus === "expire" && (
                  <div className="row">
                    {" "}
                    <text
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        color: "red",
                      }}
                    >
                      The verification code has been expired.
                    </text>
                  </div>
                )}
              </div>
              {codeStatus === "expire" && (
              <div className="mb-3" style={{display:'flex', justifyContent:'center'}}>
                  <button
                    onClick={resendLink}
                    type="button"
                    className="btn btn-primary"
                    
                  >
                   Click here To resend a new One.
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
